import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'c3Translate',
    standalone: true
  })
export class C3TranslatePipe implements PipeTransform {
    
    constructor( 
        private _translateService: TranslateService
    ) {

      }
  
    transform(value: string): string {
        return !!value ? (this._translateService.instant('TRANSLATE.' + value.trim())).toString().replace('TRANSLATE.', '') : (value || ''); 
    }
  }