// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieComponent } from 'src/app/_c3-lib/kt/components';
import { ProfileContextService } from 'src/app/services/profile-context.service';

export interface Locale {
  lang: string;
  data: any;
}

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  // Private properties
  private langIds: any = [];

  constructor(
    private translate: TranslateService,
    private profileContextService: ProfileContextService,

  ) {
    // add new langIds to the list
    this.translate.addLangs(['en']);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
  }

  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
    this.translate.use(this.getSelectedLanguage());
  }

  setLanguage(lang: string) {
    if (lang) {
      if(lang=='en-us'){
        lang= 'en'
      }else if(lang=='cn'){
        lang= 'ch'
      }
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
      CookieComponent.set('LOCALIZATION_LOCAL_STORAGE_KEY', lang, {})

    }
  }

  public translateVariables(value: string): string {
    switch (value) {
        case 'All':
            return this.translate.instant('TRANSLATE.DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_ALL');
        case '3 Months':
          return this.translate.instant('TRANSLATE.CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_QUARTER');
        case '6 Months':
          return this.translate.instant('TRANSLATE.CUSTOMER_DASHBOARD_PURCHASE_OF_SEATS_TILE_FILTER_GET_DETAILS_FOR_LAST_HALF_YEAR');
        case '12 Months':
          return this.translate.instant('TRANSLATE.DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_TWELVE_MONTHS');
        case 'Last Billing Period':
          return this.translate.instant('TRANSLATE.REVENUE_VERSUS_COST_BY_CATEGORY_FILTER_OPTION_TEXT_LAST_BILLING_PERIOD');
        case '3 Billing Periods':
          return this.translate.instant('TRANSLATE.DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_THREE_BILLING_PERIODS');
        case '6 Billing Periods':
          return this.translate.instant('TRANSLATE.DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_SIX_BILLING_PERIODS');
        case '12 Billing Periods':
          return this.translate.instant('TRANSLATE.DASHBOARD_CARDS_DURATION_UNIT_FILTER_OPTION_TEXT_LAST_TWELVE_BILLING_PERIODS');
        default:
        return value;  // Default case for any other values
    }
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    let lang = this.profileContextService?.InfoDetails?.DefaultLanguageKey || '';
    if(lang=='en-us'){
      lang= 'en'
    }
    return (
      localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) || CookieComponent.get('LOCALIZATION_LOCAL_STORAGE_KEY') || lang ||
      this.translate.getDefaultLang()
    );
  }
}
